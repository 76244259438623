import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import "./tailwind.css";
import type { LinksFunction } from "@remix-run/node";

export const links: LinksFunction = () => {
	return [
		{
			rel: "icon",
			href: "/favicon.ico",
			type: "image/x-icon",
			sizes: "32x32",
		},
		{
			rel: "icon",
			href: "/icon.svg",
			type: "image/svg+xml",
		},
		{
			rel: "apple-touch-icon",
			href: "/apple-touch-icon.png",
		},
	];
};

export function Layout({ children }: { children: React.ReactNode }) {
	return (
		<html lang="ja">
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta name="robots" content="noindex" />
				<meta property="og:image" content="/box-ogp.webp" />
				<Meta />
				<Links />
				<title>immedio Box</title>
			</head>
			<body>
				{children}
				<ScrollRestoration />
				<Scripts />
			</body>
		</html>
	);
}

export const ErrorBoundary = () => {
	const error = useRouteError();
	console.error(error);
	captureRemixErrorBoundaryError(error);
	return <div>Something went wrong</div>;
};

export default function App() {
	return <Outlet />;
}
